import React from 'react'
import Seo from '../../components/SEO'
import styled from '@emotion/styled'

import Layout from '../../components/layout'
import { colors, breakpoints, container, button } from '../../styles/theme'
import PageBody from '../../components/shared/page-body'
import evenbriteLogoSrc from '../../img/eventbrite-logo.svg'
import { StaticImage } from 'gatsby-plugin-image'

const GalaDinnerPage = () => {
  return (
    <Layout>
      <Seo
        title="Gala Dinner & James Bond Fashion Show"
        pathname="/events/gala-dinner-bond-fashion-show-2021/"
      />
      <StaticImage
        src="../../img/gala-dinner-bond-header.jpg"
        alt="Gala Dinner and James Bond Fashion Show"
        layout="fullWidth"
      />
      <EventsPageBody>
        <IntroContainer>
          <EventIntro>
            <h1 className="heading--h2">
              Gala Dinner & James Bond Fashion Show
              <br />
              <em>featuring</em> &nbsp; The Collective
              <br />
              <span style={{ fontSize: '.75em' }}>
                Thursday, 2<sup>nd</sup> December 2021 at the Marsham Court
                Hotel
              </span>
            </h1>
            <p>
              Fox Tailoring invites you to a fabulous evening of entertainment
              and dining at the Marsham Court Hotel.
            </p>
          </EventIntro>
          <TicketBlock>
            <p style={{ marginBottom: 16, marginTop: 8 }}>
              Tickets £55 per person
            </p>
            <TicketButton
              className="button"
              href="https://www.eventbrite.co.uk/e/007-bond-night-fashion-show-tickets-201557793787"
            >
              Buy Tickets
            </TicketButton>
            <img src={evenbriteLogoSrc} alt="evenbrite logo" height={20} />
          </TicketBlock>
        </IntroContainer>
        <IntroContainer
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <EventProgramme />
          <div style={{ padding: '20px 40px', maxWidth: 400 }}>
            <StaticImage
              src="../../img/dorset-childrens-foundation-logo.png"
              alt="In support of Dorset Children's Foundation"
              width={220}
            />
            <p>
              plus raffle in support of <br />
              Dorset Children's Foundation
            </p>
          </div>
        </IntroContainer>
        <Container style={{ textAlign: 'center' }}>
          <hr style={{ marginTop: 40, marginBottom: 40 }} />
          <h3 className="heading heading--h2">Dinner Menu</h3>
          <div
            style={{
              margin: '24px auto',
              display: 'grid',
              justifyContent: 'center',
              justifyItems: 'center',
              // flexFlow: 'row wrap',
              // justifyContent: 'space-around',
            }}
          >
            <div style={{ maxWidth: 440 }}>
              <h4>Starters</h4>
              <MenuList>
                <li>
                  King Prawn, Mussel & Calamari Salad with Lemon & Chive Aioli
                </li>
                <li>Goats Cheese & Pan-Fried Shallot Mousse</li>
                <li>
                  Spiced Carrot & Ginger Soup with Herbed Wholemeal Croutons (v)
                </li>
              </MenuList>
            </div>
            <div style={{ maxWidth: 440 }}>
              <h4>Mains</h4>
              <MenuList>
                <li>Roast Turkey & Baked Ham with all the Trimmings</li>
                <li>
                  Poached Fillet of Scotch Salmon, Steamed Asparagus Spears &
                  Creamy Spinach Sauce
                </li>
                <li>
                  Vegetable Wellington, Sun Dried Tomato & Roasted Red Pepper
                  Sauce(v)
                </li>
              </MenuList>
            </div>
            <div style={{ maxWidth: 440 }}>
              <h4>Desserts</h4>
              <MenuList>
                <li>Traditional Christmas Pudding with Brandy Sauce</li>
                <li>Classic Tiramisu with Amaretto Biscuit</li>
                <li>
                  Trio of Coastal Cheddar, Somerset Brie & Dorset Blue Vinny
                  with Red Onion Marmalade, Grapes & Biscuits
                </li>
              </MenuList>
              <h4>Tea, Coffee & Mints</h4>
            </div>
          </div>
          <hr />
          {/* <p style={{ margin: '24px auto' }}>
            Tables of 10 are available. Please be prepared to share.
            <br />
            Should you order a table of 10, one place will be free of charge.
          </p> */}
          <hr />
          <div style={{ display: 'inline-block', margin: '24px auto' }}>
            <p>
              Overnight accommodation is available for attendees at a special
              discounted rate of £55 for 2 people. This offer is limited so
              please book early by contacting the Marsham Court Hotel and
              quoting "Fox Tailoring Gala Dinner".
            </p>
            {/* <p>
              Overnight accommodation is available at a discounted price of £40
              per person, including breakfast. There are limited rooms
              available, so please book early. Please reserve your room(s) by
              contacting 01202 298350, quoting "Cocktails and Tailcoats
              Evening.".
            </p> */}
            <p>
              For further information please contact Fox Tailoring:
              <br />
              <strong>01202 289090</strong> or{' '}
              <strong>john@foxtailoring.com</strong>
            </p>
            <p>
              We look forward to seeing you at what should be a very exciting
              and entertaining evening.
            </p>
            {/* <p>
              Donations accepted for Meet and Greet photographs. Proceeds to The
              Second Smile Project. A Cancer registered Community Company, run
              by Cheryl Davies.
            </p> */}
          </div>
        </Container>
      </EventsPageBody>
    </Layout>
  )
}

export default GalaDinnerPage

const MenuList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 16px;
  }
`

const EventIntro = styled.section`
  flex-grow: 1 0 auto;
  max-width: 640px;
`

const TicketBlock = styled.aside`
  flex: 1 1 auto;
  background: ${colors.black};
  border: 1px solid ${colors.darkGrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  min-height: 160px;
  // min-width: 180px;
  margin: 24px 32px;
  text-align: center;
`

const TicketButton = styled.a`
  ${button.default};
  ${button.outline};
  border-color: ${colors.accent};
  color: ${colors.accent};
  font-size: 2rem;
  min-width: 180px;
  margin-bottom: 12px;
`

const EventsPageBody = styled(PageBody)`
  padding: 16px 24px 60px;
  background: linear-gradient(${colors.black}, #000 540px);
  background-size: cover;
  color: #fff;

  @media (min-width: ${breakpoints.large}px) {
    padding-top: 1px;
  }

  hr {
    border-color: ${colors.darkGrey};
  }
`

const IntroContainer = styled.div`
  ${container};
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
`

const Container = styled.div`
  ${container};
`

const EventProgramme = () => {
  return (
    <section>
      <h3 className="heading heading--h4" style={{ marginTop: 32 }}>
        Event Programme
      </h3>
      <ul>
        <li>
          <span className="scheduleTime">6.30pm</span> guests arrive
        </li>
        <li>Welcome drink</li>
        <li>3-course dinner</li>
        <li>Fashion show</li>
        <li>Entertainment from THE COLLECTIVE</li>
        <li>Disco</li>
        {/* <li>plus Raffle in aid of Dorset Children's Foundation</li> */}
      </ul>
    </section>
  )
}
